.LogIn {
  color: white;
  margin-bottom: 10px;
}

.LogIn span {
  color: #4287f5;
  cursor: pointer;
}

.LogIn span:hover {
  text-decoration: underline;
}

.errorMessage {
  color: #fa3232;
  font-size: 12px;
}

