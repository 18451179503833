@import '../../styles/app';

.map {
    margin: auto;
    height: 400px;
    width: 600px;
    position: relative;
}

.zoneSelection {
    margin: auto;
    width: 600px;
    position: relative;
}

.usersTable {
    th,
    td {
        border-color: $white;
    }

    thead {
        border-bottom: 1px solid $gray-200;
    }
}

.MapContainer {
    height: 100%;
    min-height: 60vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ChartContainer {
    height: 100%;
    min-height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.MapModal {
    height: 100%;
    min-height: 60vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: fit-content;
    margin: auto;
}

.title {
    border-bottom: 1px solid #c9c9c9;
    margin-right: 15px;
    h1 {
        padding-bottom: 10px;
        margin-bottom: 0px;
    }
}

.reportTitle {
    font-weight: bold;
    text-align: center;
}

.body {
    margin-top: 25px;
}

.stretch {
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.recordTableTitle {
    line-height: 30px;
    margin-bottom: 30px;
}

.button {
    z-index: 5;
}

.centerButton {
    z-index: 5;
    margin: auto;
}

.csvButton {
    margin-top: 40px;
}

.farmName {
    font-weight: bold;
}
