.Input {
    font-size: 14px;
    border-radius: 5px;
}

.mapContainer {
    height: 500px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 100px;
}

.InfoText {
    text-align: center;
    margin-top: 20px;
}

.handDown {
    float: right;
    margin-right: 8px;
}

.bdcallout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 2.25rem;
    border-left-width: 0.25rem;
    //border-left-color:#bfcee0;
    border-left-style: solid;
    border-radius: 0.25rem;
}

.bdcalloutColor {
    border-left-color: #bfcee0;
}

.title {
    margin-top: 2.25rem;
    color: #2a5387;
    font-size: 15px;
}
.text {
    margin: 25px 0px 0px 0px;
    display: block;
}
