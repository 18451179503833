@import '../../styles/app';

.headerLink {
    a {
        display: flex;
        color: rgb(150, 166, 173);
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
            font-weight: $font-weight-semi-bold;
        }
    }

    > a {
        color: rgb(150, 166, 173) !important;
        position: relative;
        padding: 0px 15px;
        margin-top: 10px;
        line-height: 40px;
        justify-content: space-between;

        &:hover {
            font-weight: $font-weight-thin;
            background-color: rgb(35, 45, 55);
        }

        img {
            width: 15px;
            margin-right: 15px;
        }
    }
}

.arrow {
    color: $gray-400;
    transition: 0.3s;
    right: 0;
}

.arrowActive {
    transform: rotate(-90deg);
}

.headerLinkActive {
    span {
        color: rgb(238, 238, 238);
        line-height: 40px;
        font-weight: $font-weight-semi-bold;
    }
}

.panel {
    border: none;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background: rgba(#000, 0.1);

    div {
        padding: 0;
    }

    ul {
        padding-left: 0;
        font-size: $font-size-small;

        li {
            padding: 3px 0;
            list-style: none;
        }

        a {
            padding: 4px 20px 4px 44px;

            &:hover,
            &:active,
            &:focus {
                font-weight: $font-weight-semi-bold;
                color: rgb(238, 238, 238);
            }
        }
    }
}

.icon {
    line-height: 40px;
    margin-right: 15px;
    margin-left: 10px;
}
