@import '../../styles/app';

.logo {
    max-width: 300px;
    margin-bottom: 15vh;
}

.widget {
    max-width: 320px;
    margin: 0 auto;
    padding: 30px !important;
    background: rgba(0, 0, 0, 0.5);

    :global .form-control {
        font-size: 14px;
    }
}

.SignUp {
    color: white;
    margin-bottom: 10px;

    span {
        color: #4287f5;
        cursor: pointer;
    }

    span:hover {
        text-decoration: underline;
    }
}
