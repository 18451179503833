@import '../../styles/app';

.root {
    margin-top: 5%;
}

.container {
    margin: 0 auto;
    padding: 7px $content-padding-horizontal;
    font-weight: 600;
    color: rgb(27, 36, 48);
}

.spacer {
    display: inline-block;
    padding: 0 5px;
}
