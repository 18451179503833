@import '../../styles/app';

.root {
    width: $sidebar-width-open;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(27, 36, 48);
    color: $sidebar-color;
}

.logo {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: $navbar-height;
    width: 100%;
    background-color: rgb(250, 250, 250);
    a img {
        width: $sidebar-width-open - 40px;
    }
}

.title {
    color: rgb(238, 238, 238);
    position: relative;
    top: 20px;
    left: 20px;
}

.nav {
    padding: 10px 0;
    overflow-y: auto;
}
