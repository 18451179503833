@import '../../styles/app';

.root {
    background: $navbar-bg-color;
    box-shadow: 0 4px 6px -6px #222;

    :global {
        .input-group {
            background-color: $gray-100;

            @include media-breakpoint-down(xs) {
                display: none;
            }

            input {
                background: transparent;
                border: none;
                width: 250px;

                @include media-breakpoint-down(md) {
                    width: 100px;
                }

                @include media-breakpoint-down(sm) {
                    width: 175px;
                }

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $gray-300;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $gray-300;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $gray-300;
                }

                &:hover,
                &:active,
                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }

            .input-group-append {
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 1.25rem;
                    color: theme-color('light');
                }
            }
        }

        .dropdown-menu {
            border: none;
            margin-top: 0.7rem;
        }

        .dropdown-item {
            &:active {
                background-color: $gray-200;
                color: $text-color;
            }

            &:focus {
                outline: none;
            }

            a {
                display: block;
                color: $text-color;

                &:hover {
                    color: $text-color;
                    text-decoration: none;
                    cursor: default;
                }
            }
        }
    }
}

.arrow {
    color: $gray-400;
    transition: 0.3s;
    right: 0;
}

.arrowActive {
    transform: rotate(180deg);
}

.adminPhoto {
    width: 40px;
    height: 40px;
}

ol.breadcrumb {
    margin: auto auto auto auto;
}

.headerIcon {
    display: flex;
    align-items: center;
    position: relative;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    :global .btn {
        background: $white;
        border: none;

        &:hover,
        &:active,
        &:focus,
        &:focus:active {
            background: $white;
            box-shadow: none !important;
            outline: none;
        }
    }

    &:nth-child(n + 2) {
        margin-left: 0.5rem;
    }

    &:nth-child(2) {
        span {
            right: -1px;
        }
    }

    img {
        max-height: 1.65rem;
        width: 1.65rem;
    }

    span {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 12px;
        right: -6px;
        color: $white;
        font-size: 0.55rem;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: theme-color('danger');
    }
}

.sidebarToggler {
    display: flex;

    @include media-breakpoint-up(md) {
        display: none !important;
    }
}
