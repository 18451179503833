@import '../../styles/app';

.title {
    border-bottom: 1px solid #c9c9c9;
    margin-right: 15px;
    h1 {
        padding-bottom: 10px;
        margin-bottom: 0px;
    }
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 2em;
}

.mainTable {
    td,
    thead {
        border-top: 1px solid $gray-200;
        padding: 1.25rem 1rem;
    }

    thead th {
        color: theme-color('light');
        text-transform: uppercase;
    }

    td, th {
        text-align: center;
    }
}

.center {
    position: absolute;
    margin: auto;
    top: 20px;
    bottom: 20px;
    left: 0;
    right: 0;
}

.dropDown {
    position: relative;
    margin-right: 3rem;
}

.deleteButton {
    position: relative;
    margin-right: 1rem;
    border-color: #eb3349;
    color: #eb3349;
    background: #fff;
}
.deleteButton:hover {
    color: #fff;
    background: #eb3349;
    border-color: #eb3349;
}
.deleteButton:active {
    color: #fff;
    background: #ce2a3e;
    border-color: #ce2a3e;
}
.deleteButton:after {
    color: #fff;
    background: #ce2a3e;
    border-color: #ce2a3e;
}

.text {
    margin: 25px 0px 0px 0px;
    display: block;
}
