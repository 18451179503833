.center {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: auto;
}

.center input {
    width: fit-content;
    margin-left: 2vw;
}

.center label {
    font-size: x-large;
    margin-left: 2vw;
}

#table thead {
    position: sticky;
    top: 0;
}

#table tbody th {
    position: sticky;
    left: 0;
}

#table tbody th:first-child {
    left: 0;
    z-index: 2;
}

#table thead th:not(#table thead tr th:first-child) {
    background: #7d76e4;
    color: #fff;
    /* Ensure this stays above the emulated border right in tbody th {}: */
    z-index: 1;
}

#table thead tr:nth-child(2) th:not(#table thead tr th:first-child):hover {
    height: calc(100% - 2px);
    cursor: pointer;
    background: #7d76e4;
    color: #fff;
    z-index: 1;
    width: calc(100% - 2px);
    border: 1px solid black;
    border-bottom-width: 2px;
}

#table tbody th {
    background: #8dec9fe1;
    border-right: 1px solid #ccc;
    /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
    box-shadow: 1px 0 0 0 #ccc;
}

td,
th {
    padding: 0.5em;
}
