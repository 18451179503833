// Container

.container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

// Navbar

.navbar {
    border: none;
    height: $navbar-height;
    border-radius: $navbar-border-radius;
    margin-bottom: $navbar-margin-bottom;
    padding: 0.5rem 2.25rem;
}

@media (min-width: $grid-float-breakpoint) {
    .navbar-right {
        .dropdown-menu {
            left: 0;
        }
    }
}

// Nav

.nav {
    &.nav-tabs {
        .nav-item {
            a {
                color: $text-color;

                &.active {
                    background-color: theme-color('danger');
                    border-color: theme-color('danger');
                    color: white;
                }
            }
        }
    }
}

// Progress

.progress-bar {
    @include box-shadow(none);
}

.progress-sm {
    height: 1rem;
    margin-bottom: 1rem;
}

.progress-xs {
    height: 5px;
    margin-bottom: 0.5rem;
}

// Breadcrumb

.breadcrumb {
    margin: auto auto auto auto;

    font-size: $font-size-small;

    > .active {
        font-weight: 600;
    }

    > li + li::before {
        color: $gray-light;
    }
}

// Form

.form-control {
    font-size: $font-size-base;

    @include box-shadow(none);
    @include transition(border-color ease-in-out 0.15s, background-color ease-in-out 0.15s);

    &:focus {
        @include box-shadow(none);
    }

    &.no-border {
        border: none;
        background-color: darken($input-bg, 5%);

        &:focus {
            background-color: darken($input-bg, 7%);
        }
    }
}

.input-sm {
    font-size: 13px;
}

// Buttons

.btn-inverse {
    @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}

// Alerts

.alert {
    font-size: $font-size-small;

    .close {
        color: rgba(#000, 0.4);
    }
}

.alert-sm {
    padding: 10px 15px;

    .close {
        font-size: 20px;
        top: 0;
        right: -8px;
    }
}

// Badge

.badge {
    font-size: 12px;

    .list-group-item > & {
        margin-top: 2px;
    }
}

// Table

.table-no-border {
    margin-left: -$table-cell-padding;
    margin-right: -$table-cell-padding;

    > thead > tr > th,
    > thead > tr > td,
    > tbody > tr > th,
    > tbody > tr > td,
    > tfoot > tr > th,
    > tfoot > tr > td {
        border-top: 0;
    }
}

.table-sm {
    font-size: $font-size-small;

    > thead > tr > th,
    > thead > tr > td,
    > tbody > tr > th,
    > tbody > tr > td,
    > tfoot > tr > th,
    > tfoot > tr > td {
        padding: 4px;
    }

    &.table-no-border {
        margin-left: -4px;
        margin-right: -4px;
    }
}

// Code

pre {
    display: block;
    padding: 6.5px;
    margin: 0 0 (1rem / 2);
    font-size: 13px;
    line-height: 1rem;
    word-break: break-all;
    word-wrap: break-word;
    color: $pre-color;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: $border-radius;

    // Account for some code outputs that place code tags in pre tags
    code {
        padding: 0;
        font-size: inherit;
        color: inherit;
        white-space: pre-wrap;
        background-color: transparent;
        border-radius: 0;
    }
}

// List

.list-group {
    padding: 0 1.25rem;
    background: $white;

    .list-group-item {
        border: none;
        padding: 1.25rem 0;
        color: theme-color('info');
        border-top: 1px solid $gray-200;

        &:hover {
            z-index: 0;
        }

        &:first-child {
            border-top: none;
        }
    }
}

// Google Map

.pac-container {
    z-index: 1100 !important;
}
