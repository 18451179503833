.table {
  border-collapse: collapse;
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
}

.table tr,
.table td,
.table th {
  border: 1px solid black;
  width: fit-content;
  padding-inline: 0.5rem;
}

.table th {
  font-weight: bold;
}

.table caption {
  caption-side: top;
  color: black;
  font-size: xx-large;
  text-align: center;
}

