.Input {
    font-size: 14px;
    border-radius: 5px;
}

.mapContainer {
    height: 500px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 100px;
}

.InfoText {
    text-align: center;
    margin-top: 20px;
}

.handDown {
    float: right;
    margin-right: 8px;
}

.instructions {
    margin-top: 30px;
}

.instructions label {
    float: left;
}

.text {
    margin: 25px 0px 0px 0px;
    display: block;
}

.input {
    width: max-content;
    display: initial;
    margin-left: 2rem;
    float: right;
    max-width: 75%;
}
