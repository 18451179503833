@import '../../../styles/app';

.root {
    :global {
        .location-selector {
            width: 100%;
            height: 220px;
            border: 1px dashed #bbb;
            background-color: $white;
            position: relative;
        }

        .location-selector .bit {
            @include transition(background-color 0.15s ease-in-out);

            background-color: $gray-300;
            cursor: pointer;
            position: absolute;
        }

        .location-selector .bit:hover {
            background-color: $gray-400;
        }

        .location-selector .bit.top,
        .location-selector .bit.bottom {
            height: 20%;
            width: 20%;
            margin: 0 40%;
        }

        .location-selector .bit.top {
            top: 0;
        }

        .location-selector .bit.bottom {
            bottom: 0;
        }

        .location-selector .bit.right,
        .location-selector .bit.left {
            height: 20%;
            width: 20%;
            margin-left: 0;
            margin-right: 0;
        }

        .location-selector .bit.right {
            right: 0;
        }

        .location-selector .bit.left {
            left: 0;
        }
    }
}
