.mapLabel {
    height: 30px;
    padding: 0px 5px;
    border: 1px solid #15224d;
    border-radius: 10px;
    background: #92a6e8;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #15224d;
}

.floatingPannel {
    z-index: 6;
    position: absolute;
    top: 10px;
    left: 230px;
}

.colorScale {
    z-index: 5;
    position: absolute;
    top: 10px;
    right: 100px;
    width: 200px;
    background: white;
    padding: 5px 10px;
    font-size: 10px;
}

.heading {
    font-size: 13px;
}

.slider {
    z-index: 5;
    position: absolute;
    bottom: 5px;
    background: white;
    left: 100px;
    width: 230px;
    padding-left: 20px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-top: 5px;
}
